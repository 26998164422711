$font-config: (
  thin: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_thin-webfont',
    normal,
    100
  ),
  light: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_light-webfont',
    normal,
    300
  ),
  regular: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft-webfont',
    normal,
    400
  ),
  medium: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_medium-webfont',
    normal,
    500
  ),
  italic: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_italic-webfont',
    italic,
    400
  ),
  medium-italic: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_medium_italic-webfont',
    italic,
    500
  ),
  black: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_black-webfont',
    normal,
    900
  ),
  black-italic: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_black_italic-webfont',
    italic,
    900
  ),
  bold: (
    'exo_soft',
    '../fonts/exo_soft/exo_soft_bold-webfont',
    normal,
    700
  )
);

@mixin font-face($font-family, $font-path, $font-style, $font-weight){
  @font-face {
    font-family: $font-family;
    src: url('#{$font-path}.woff2') format('woff2'), /* Pretty Modern Browsers */
        url('#{$font-path}.woff') format('woff'), /* Pretty Modern Browsers */
        url('#{$font-path}.ttf')  format('truetype'), /* Safari, Android, iOS */
        url('#{$font-path}.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-style: $font-style;
    font-weight: $font-weight;
  }
}

// Include font family
@each $font, $type in $font-config {
  $font-name: nth($type, 1);
  $font-path: nth($type, 2);
  $font-style: nth($type, 3);
  $font-weight: nth($type, 4);
  @include font-face($font-name, $font-path, $font-style, $font-weight);
}
