.wrapper {
  background: #f5f5f5;
}
.main-content {
  text-align: left;
  padding-bottom: 130px;

  p {
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .white-card {
    background: #fff;
    margin-bottom: 30px;
  }
}