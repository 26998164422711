.nav-tabs {
  @media (max-width: 499px) {
    border-bottom: 0;
  }
  > li {
    @media (max-width: 499px) {
      border-bottom: 1px solid #ddd;
      margin-bottom: 5px;
      text-align: center;
      &:first-child, &:nth-child(2) {
        width: 50%;
      }
      &:last-child, &:nth-child(3), &:nth-child(4) {
        width: calc(100% / 3);
      }
      &:nth-child(3) {
        clear: both;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      height: 2px;
      background-color: #dedede;
      z-index: 10;
      @media (max-width: 499px) {
        display: none;
      }
    }
    > a {
      border-radius: 0;
      color: #0f3655;
      font-size: 14px;
      font-weight: 600;
      margin-right: 0;
      padding: 7px 15px;
      @media (max-width: 991px) {
        font-size: 12px;
        padding: 7px;
      }
      &:hover {
        border-color: transparent;
      }
    }
    &.active {
      @media (max-width: 499px) {
        border-bottom: 0!important;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #4e74d9;
        z-index: 10;
        transition: height 0.2s;
        @media (max-width: 499px) {
          display: block;
        }
      }
      > a {
        //border-top-color: #4e74d9;
      }
    }
  }
}

.tab-content {
  padding: 15px;
  @media (max-width: 991px) {
    padding: 15px 0;
  }

  .table-details {
    td {
      border-top: 0;
      border-bottom: 1px solid #eee;
      &:first-child {
        width: 35%;
        color: #0f3655;
        font-weight: 600;
      }
    }
  }
}