.form-control {
  border: 1px solid #d8d8d8;
  border-radius: 0;
  resize: none;

  &::placeholder {
    color: #0f3655;
    font-size: 14px;
    font-weight: 400;
  }
}

.btn {
  border-radius: 0;
  border: none;
  padding: 8px 30px;
  transition: 0.3s;
}
.btn-success {
  background-color: #72c959;
}

//radio buttons
.radio {
  padding-left: 0;
  margin-top: 0;
  input[type="radio"] {
    display:none;
  }
  label {
    color: #0f3655;
    font-size: 14px;
    font-weight: 400;
  }
  input[type="radio"] + label span {
    display:inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #979797;
    border-radius: 100%;
    margin: -2px 5px 0 0;
    vertical-align:middle;
    cursor:pointer;
    position: relative;
  }
  input[type="radio"]:checked + label span:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #0f3655;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.radio-inline {
    display: inline-block;
    vertical-align: middle;
  }
}
.radio-inline+.radio-inline {
  margin-left: 20px;
}

.filter-group {
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  border: 1px solid #dedede;
  background-color: #f5f5f5;

  .radio-inline {
    margin-left: 0;
    margin-bottom: 10px;
    width: calc(25% - 3px);
    @media (max-width: 991px) {
      width: calc(50% - 3px);
    }
  }
}