.row--flex {
  & > div {
    margin-bottom: 40px;
  }
}

.previews-section {
  .container-fluid {
    max-width: 1084px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.preview-article {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 12px 15px;
  height: 100%;
  margin-bottom: 40px;
  box-shadow: 0 2px 14px rgba(64, 71, 79, 0.1);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  #{'.row--flex'} & {
    margin-bottom: 0;
    height: 100%;
  }

  @include media-breakpoint-up(sm) {
    padding: 12px 18px;
  }

  &.preview-article--wide {
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .priview-article-figure {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-right: 40px;
        margin-bottom: 0;
        display: block;
        max-width: 45%;
      }
    }
  }

  &.preview-article--wide,
  &.preview-article--half {
    border: 3px solid gold;
  }

  &.preview-article--half,
  &.preview-article--simple {
    padding-bottom: 54px;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding-bottom: 69px;
    }

    .preview-article-features {
      border-top: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 17px 18px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 1px;
        width: calc(100% - 36px);
        background: #d8d8d8 ;
      }

      @include media-breakpoint-down(sm) {
        padding: 17px 18px 30px;
      }
    }

    .preview-article-title {
      @include media-breakpoint-up(md) {
        max-height: 44px;
        overflow: hidden;
        min-height: 44px;
      }
    }
  }

  &.preview-article--simple {
    padding-bottom: 106px;
    // padding-bottom: 130px;
    .preview-article-total {
      position: absolute;
      bottom: 54px;
      left: 0;
      margin-left: 18px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 126px;
    }
  }

  .priview-article-figure {
    margin-bottom: 15px;
  }

  .preview-article-content {
    text-align: left;
  }

  .preview-article-title {
    margin-top: 0;
    color: #0f3655;
    font-size: 20px;
    font-weight: 500;
    // max-height: 44px;
    // overflow: hidden;
    // min-height: 44px;

    a {
      color: #0f3655;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .preview-article-meta {

    .address {
      padding-left: 15px;
      position: relative;
      color: #9b9b9b;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 10px;
      &:after {
        position: absolute;
        content: "";
        top: 4px;
        left: 0;
        width: 9px;
        height: 11px;
        background-image: url('../images/map-marker.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .details {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .details-label {
        padding: 5px 15px;
        margin-right: 15px;
        height: 24px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-radius: 100px;

        &.details-label--type-1 {
          color: #5a94d7;
          background: rgba(188, 240, 255, .54);
        }

        &.details-label--type-2 {
          color: #e3787d;
          background: rgba(255, 210, 218, .54);
        }
      }

      .details-img {
        max-width: 80px;
      }

      .details-price {
        font-size: 18px;
        font-weight: 400;
        color: #0f3655;
        margin-right: 20px;
      }
    }
  }

  .preview-article-descr {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #4a4a4a;
  }

  .preview-article-total {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  .btn--article {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
    border-radius: 0;
    border: none;
    outline: none;
    background-color: #6971eb;
    transition: background-color .3s;
    &:hover {
      background-color: darken(#6971eb, 10%);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .preview-article-contacts {
    font-weight: 400;
    color: #4a4a4a;
    line-height: 1.6;

    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 60px;
    }

    a {
      color: #5657e5;
      text-decoration: underline;
    }
  }

  .preview-article-features {
    border-top: 1px solid #d8d8d8;
    padding: 17px 5px;
  }

  .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .features-item-qnt {
      margin-right: 3px;
      color: #163c5a;
    }

    .feature-item-img {
      width: 30px;
    }
  }
}


.features-item {
  display: flex;
  align-items: center;
  line-height: 1;

  & + .features-item {
    margin-left: 10px;

    @include media-breakpoint-up(sm) {
      margin-left: 20px;
    }

    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }
  }

  #{'.preview-article--simple'} & {
    & + .features-item {
      margin-left: 10px;
      @include media-breakpoint-up(md) {
        margin-left: 25px;
      }

    }
  }
}

// Styles Override
#map_canvas {
  height: 475px;
}

.pagination>li>a, .pagination>li>span {
  height: 65px;
  line-height: 51px;
}

.result_header_background {
  @media (max-width: 1100px) {
    height: 370px;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    height: 600px;
    padding: 0 15px;
  }

  @media (max-width: 500px) {
    height: 660px;
    padding: 0 15px;
  }
}

.find-properties-panel {
  @media (max-width: 1100px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}


.home-search-panel {
  width: 860px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
}

.search-by-location,
.search-by-keyword {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.property-type,
.price,
.bedrooms,
.bathrooms {
  @media (max-width: 1100px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;
  }
}


.price-min,
.price-max {
  @media (max-width: 768px) {
    min-width: 100%;
  }
}



//.property-type, .price, .bedrooms, .bathrooms