// Tools: Mixins

// Center content
@mixin content-center($width){
	margin-right: auto;
	margin-left: auto;
	@if $responsive == 'true' {
		max-width: $width;
		width: 100%;
	} @else {
		width: $width;
	}
}

// Making gradient from certain color to transparence
@mixin transparent-gradient($color-from, $color-middle, $fullness, $direction, $color-to: rgba(255,0,0,0)) {
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
		background-image: linear-gradient(to $direction, $color-from 0%, $color-middle $fullness, $color-to 100%);
  }

	&:not(.bg--gray-right) {
		.btn {
			color: $color-middle;
			&:hover { background: $color-middle; color: $color-white; }
		}
	}
}

@mixin post-link ($color, $hover-color) {
	position: relative;
	color: $color;
	&:after {
		position: absolute;
		content: "";
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background: $brand-primary;
		opacity: 0;
		will-change: transform;
		transform: translateY(2px);
		transition: opacity .2s, transform .2s;
	}
	&:hover {
		color: $hover-color;
		text-decoration: none;
		&:after {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
