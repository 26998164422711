.navbar {
  &.navbar-blue {
    margin-bottom: 30px;
    background: {
      color: #77b3f9!important;
    };
    .navbar-toggle {
      margin-right: 0;
    }
    .menu-centre {
      float: right!important;
      margin-left: 0!important;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .navbar-left:not(.main_menu_item) {
      margin-left: 0!important;
      @media (max-width: 767px) {
        position: absolute;
      }
      .moving-up-logo {
        width: 40px;
        height: auto;
      }
      > p {
        margin: 0 0 0 10px;
        > span {
          display: block;
          &.logo-text {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 2px;
            @media (max-width: 499px) {
              font-size: 20px;
              margin-top: 2px;
            }
          }
          &.logo-sub-text {
            font-size: 10px;
            @media (max-width: 499px) {
              font-size: 8px;
            }
          }
        }
      }
    }
    .main_menu_item {
      color: #fff;
      font-weight: bold;
      font-size: 14px!important;
      &:hover {
        color: #023b5a;
      }
    }
    .navbar-nav {
      @media (max-width: 767px) {
        margin: 0;
        padding: 15px 0;
      }
    }
  }
}