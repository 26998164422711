// Flex Wrapper
.flex {
  display: flex;
  @supports(display: flex) {
    &:before,
    &:after {
      display: none!important;
    }
  }

  &--middle {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--baseline {
    align-items: baseline;
  }
}
