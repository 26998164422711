$icomoon-font-path: "../fonts/plvisionicons/" !default;

$fi-google-plus: "\e90a";
$fi-email: "\e907";
$fi-account-user: "\e905";
$fi-marker: "\e906";
$fi-skype: "\e908";
$fi-telephone: "\e909";
$fi-menu: "\e904";
$fi-twitter: "\e900";
$fi-slideshare: "\e901";
$fi-linkedin: "\e902";
$fi-fb: "\e903";


@font-face {
  font-family: 'plvision';
  src:  url('#{$icomoon-font-path}/plvision.eot?bakw94');
  src:  url('#{$icomoon-font-path}/plvision.eot?bakw94#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/plvision.ttf?bakw94') format('truetype'),
    url('#{$icomoon-font-path}/plvision.woff?bakw94') format('woff'),
    url('#{$icomoon-font-path}/plvision.svg?bakw94#plvision') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fi-"], [class*=" fi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'plvision' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  color: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-google-plus {
  &:before {
    content: $fi-google-plus;
  }
}
.fi-email {
  &:before {
    content: $fi-email;
  }
}
.fi-account-user {
  &:before {
    content: $fi-account-user;
  }
}
.fi-marker {
  &:before {
    content: $fi-marker;
  }
}
.fi-skype {
  &:before {
    content: $fi-skype;
  }
}
.fi-telephone {
  &:before {
    content: $fi-telephone;
  }
}
.fi-menu {
  &:before {
    content: $fi-menu;
  }
}
.fi-twitter {
  &:before {
    content: $fi-twitter;
  }
}
.fi-slideshare {
  &:before {
    content: $fi-slideshare;
  }
}
.fi-linkedin {
  &:before {
    content: $fi-linkedin;
  }
}
.fi-fb {
  &:before {
    content: $fi-fb;
  }
}
