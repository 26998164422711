// Tools: Function

// Pow number
// Calc number digits
// In our evemt we have 100
@function pow($x, $n) {
	$return: 1;
	@if $n >= 0 {
		@for $i from 1 through $n {
			$return: $return * $x;
		}
	} @else {
		@for $i from $n to 0 {
			$return: ($return / $x);
		}
	}
	@return $return;
}

// Round number
// $digits - how much number leave after comma
@function round-number($float, $digits: 2){
	$sass-precision: 5;
	@if $digits > $sass-precision {
		@warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
		+ "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
		+ "See https://github.com/sass/sass/issues/1122 for further informations.";
	}
	$pow: pow(10, $digits);
	@return ((round($float * $pow))/$pow);
}

// Convert pixel into em
@function em($pixel, $font-size: $default-font-size){
	$result: round-number(($pixel / $font-size));
	@return ($result * 1em);
}