.share {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: space-around;
  }
  li {
    a {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      position: relative;
      transition: opacity 0.3s;
      img, span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .facebook {
    background-color: #3b5998;
  }
  .google {
    background-color: #dc4e41;
  }
  .twitter {
    background-color: #55acee;
  }
  .add-share {
    background-color: #dedede;
    color: #acacac;
    font-size: 20px;
    font-weight: 600;
  }
}