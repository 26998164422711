.advert-header {
  padding: 15px 30px;
  border-bottom: 1px solid #eeeeee;
  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 499px) {
    .pull-left, .pull-right {
      width: 100%;
    }
    .pull-left {
      margin-bottom: 15px;
    }
  }
  h4 {
    margin: 0 0 5px;
    color: #0f3655;
    font-weight: 500;
  }
}
.location {
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 400;
  padding-left: 13px;
  background: {
    image: url('../images/map-marker.png');
    position: left;
    repeat: no-repeat;
  };
}

.advert-in {
  padding: 20px 30px;
  @media (max-width: 991px) {
    padding: 15px;
  }
  ul {
    li {
      display: inline-block;
      vertical-align: middle;
      color: #0f3655;
      padding: 0 10px;
      border-right: 1px solid #dedede;
      @media (max-width: 1199px) {
        padding: 0 5px;
      }
      @media (max-width: 991px) {
        padding: 0;
        width: calc(100% / 3 - 4px);
        border: none;
        margin-bottom: 10px;
      }
      @media (max-width: 499px) {
        width: calc(50% - 4px);
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
      img {
        margin-right: 10px;
        vertical-align: middle;
        @media (max-width: 1199px) {
          margin-right: 5px;
        }
      }
      span {
        vertical-align: middle;
      }
    }
  }
}

.advert-description {
  padding: 0 30px 15px;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 991px) {
    padding: 0 15px 15px;
  }
}

.advert-tabs {
  padding: 15px;
}

.advert-contact {
  padding: 20px 30px;
  @media (max-width: 991px) {
    padding: 15px;
  }
  h5 {
    color: #0f3655;
    font-size: 16px;
    margin: 0 0 20px;
    font-weight: 600;
  }
  .agent-contacts {
    color: #0f3655;
    font-size: 14px;
    font-weight: 400;
    > div {
      padding: 8px 0 8px 25px;
      border-bottom: 1px solid #eee;
      background: {
        position: left;
        repeat: no-repeat;
      };
    }
    .phone {
      background-image: url('../images/phone-icon.png');
    }
    .mobile {
      background-image: url('../images/mobile-icon.png');
    }
    .email {
      background-image: url('../images/email-icon.png');
    }
  }
  .radio-inline {
    @media (max-width: 1199px) {
      display: block!important;
      margin: 0 0 5px!important;
    }
  }
}

.advert-similar {
  padding: 20px 30px;
  @media (max-width: 991px) {
    padding: 15px;
  }
  h5 {
    color: #0f3655;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 20px;
  }
}

.similar-item {
  display: block;
  text-decoration: none!important;
  @media (max-width: 499px) {
    margin-bottom: 30px;
  }
  &:hover {
    .similar-img {
      img {
        transform: scale(1.05);
      }
    }
  }
  .similar-img {
    display: block;
    overflow: hidden;
    margin-bottom: 15px;
    @media (max-width: 499px) {
      margin-bottom: 10px;
    }
    img {
      transition: transform 0.2s;
      margin-bottom: 0;
      width: 100%;
      max-width: none;
    }
  }
  .similar-title {
    color: #0f3655;
    font-weight: 600;
    font-size: 14px;
  }
  .similar-info {
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
  .similar-price {
    color: #0f3655;
    font-size: 18px;
    font-weight: 400;
  }
}

.blue-title {
  padding: 10px 20px;
  background-color: #4e74d9;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.advert-agent {
  padding: 20px;
  img {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
    color: #0f3655;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  .btn {
    width:100%;
  }
}

.advert-actions {
  li {
    border-bottom: 1px solid #eee;
    a {
      display: block;
      padding: 8px 15px 8px 40px;
      text-decoration: none;
      color: #5994d8;
      font-size: 14px;
      font-weight: 600;
      transition: background 0.2s;
      background: {
        repeat: no-repeat;
        position: 10px center;
      };

      &:hover {
        background-color: #eee;
      }
    }
  }
}

.local-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  h4 {
    color: #0f3655;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 20px;
  }
  .nearby {
    padding: 0 0 15px 25px;
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 400;
    background: {
      repeat: no-repeat;
      position: left top;
    };
    &.metro {
      background-image: url('../images/metro-icon.svg');
    }
    &.airport {
      background-image: url('../images/airport-icon.svg');
    }
    &.pier {
      background-image: url('../images/pier-icon.svg');
    }
    &.school {
      background-image: url('../images/school-icon.svg');
    }
  }
}