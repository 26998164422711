.panel-group {
  .panel-default {
    border-radius: 0;
    border: 1px solid #dedede;
  }
  .panel-heading {
    border-radius: 0;
    background-color: #f5f5f5;
    padding: 0;
  }
}

.floor-plan {
  .panel-heading {
    .minus {
      display: inline-block;
      vertical-align: middle;
      font-size: 0;
      margin-right: 5px;
      margin-top: -2px;
      width: 10px;
      height: 2px;
      background-color: #0f3655;
    }

    .panel-title {
      text-decoration: none;
      color: #0f3655;
      padding: 10px 15px;
      display: block;
      > span {
        font-size: 12px;
        font-weight: 600;
      }
      > span, ul {
        display: inline-block;
        vertical-align: middle;
        width: calc(50% - 4px);
        @media (max-width: 991px) {
          width: auto;
        }
      }
      > ul {
        text-align: right;
        @media (max-width: 991px) {
          float: right;
        }
        li {
          display: inline-block;
          vertical-align: middle;
          color: #4a4a4a;
          font-size: 12px;
          margin-left: 20px;
          @media (max-width: 991px) {
            margin-left: 10px;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .panel+.panel {
    margin-top: 0;
  }
}