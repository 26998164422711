// body {
//   font-family: $font-family-sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   // line-height: 28px;
//   line-height: 1.7;
//   font-size: 17px;
//   color: $text-dark;
//   // overflow-x: hidden;
// }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

// h1,h2,h3,h4,h5,h6 {
//   position: relative;
//   font-weight: 500;
//   letter-spacing: .4px;
//   line-height: 1.2;
//   margin: 0 0 1em 0;
// }

// a {
//   color: $brand-primary;
//   text-decoration: none;
//   transition: color .3s;
//
//   &:hover,
//   &:focus {
//     text-decoration: underline;
//     outline: none;
//   }
//
//   #{'.section--dark'} & {
//     color: #0094d9;
//   }
// }


// input,
// button,
// select {
//   outline: none !important;
// }
//
// p {
//   margin-top: 0;
//   &:last-child { margin-bottom: 0; }
// }
//
// label {
//   font-weight: 500;
//   color: #6c7680;
// }
//
// button {padding: 0; border:none}
//
// // disable select arrow in IE
// select::-ms-expand { display: none;}
//
// // remove input number styles
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
//
// input[type=number] {
// -moz-appearance: textfield;
// }
//
//
// input {
//   background: $color-white;
//   // -webkit-text-fill-color: $input-color !important;
// }
//
//
// input:-webkit-autofill {
//   -webkit-text-fill-color: $input-color !important;
//   -webkit-box-shadow: inset 0 0 0px 9999px $color-white;
//   &:focus {
//     -webkit-text-fill-color: $input-color !important;
//     -webkit-box-shadow: inset 0 0 0px 9999px $color-white;
//   }
// }
//
//
// // noresize textarea's width
// textarea { width: 100%!important; }
//
// canvas {
//   -moz-user-select: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
// }
//
// address {
//   font-style: normal;
// }
//
// pre {
//   white-space: pre-line;
// }
//
// figure {
//   margin: 0;
// }

.cover-img {
  width: 100%;
  object-fit: cover;
}

// Wordpress Classes
.alignleft   {
  float: left;
  margin-right: 10px;
}

.alignright  {
  float: right;
  margin-left: 10px;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

.js-hidden-content {
  display: none;
}

.scrolled-wrapper {
  @include media-breakpoint-down(sm) {
    overflow: auto;
    width: 100%;

    img {
      max-width: none;
    }
  }
}

body.admin-bar {
  margin-top: 32px!important;

  @media screen and (max-width: 782px) {
    margin-top: 46px!important;
  }

  .header {
    top: 32px;

    @media screen and (max-width: 782px) {
      top: 46px;
    }

    &.header--stiky {
      top: 0;
    }

    & + * {
      margin-top: 77px;

      @media screen and (max-width: 782px) {
        margin-top: 25px!important;
      }
    }
  }
}

.container-custom {
  @media (min-width: 1200px) {
    max-width: 1080px;
  }
}

.col-xxs-12 {
  @media (max-width: 499px) {
    width: 100%!important;
    img {
      max-width: none;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}