// Tools
// @import "vendor/bootstrap-custom";
@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/functions';
@import 'utils/breakpoints';
@import 'utils/spacing';


// Reset
// @import 'base/normalize';

// Base
@import "base/global";
@import "base/fonts";
@import "base/icons";

// Layout
// @import "layout/header";
// @import "layout/footer";
@import "layout/flex";
@import "layout/row";


// @import "layout/content";
// @import "layout/sections";
// @import "layout/gradient-bg";

// Pages
// @import "pages/companies";
// @import "pages/gallery";
// @import "pages/not-found";

// Vendor
// @import "vendor/bootstrap-custom";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";
// @import "vendor/wowanimate";
// @import "vendor/magnific-popup";

// Modules
@import "modules/content-article";
@import "modules/slick-slider-custom";
@import "modules/navbar";
@import "modules/main-content";
@import "modules/advert";
@import "modules/tabs";
@import "modules/forms";
@import "modules/share";
@import "modules/accordion";
// @import "modules/buttons";
// @import "modules/forms";
// @import "modules/lists";
// @import "modules/table";
// @import "modules/texts";
// @import "modules/titles";
// @import "modules/contacts";
// @import "modules/social-icons";
// @import "modules/banner-slider";
// @import "modules/cards";
// @import "modules/services";
// @import "modules/post";
// @import "modules/post-single";
// @import "modules/categories-nav";
// @import "modules/banners";
// @import "modules/challenges";
// @import "modules/offsets";
// @import "modules/tabs";
// @import "modules/option-card";
// @import "modules/embed-youtube";
// @import "modules/pagination";
// @import "modules/figure";
// @import "modules/brands";
// @import "modules/team-member";
// @import "modules/benefit-card";
// @import "modules/testimonials";
// @import "modules/testimonial-card";
// @import "modules/album-card";
// @import "modules/collage";
// @import "modules/dropdowns";

//Bootstrap Rewrite
// @import "modules/utilities";
