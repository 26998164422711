.slider-images {
  display: none;
  max-height: 320px;
  margin-bottom: 2px;

  &.slick-initialized {
    display: block;
  }

  .slick-list,
  .slick-track {
    max-height: 320px;
  }
}

.slider-nav {
  display: none;
  &.slick-initialized {
    display: block;
  }

  .slider-item {
    max-height: 70px;
    cursor: pointer;
  }
}

.slick-slide {
  margin: 2px;
  position: relative;
  border-radius: 0;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 102%;
    opacity: 1;
    pointer-events: none;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity .3s;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &.slick-current {
    border: none;
    &:after {
      opacity: 0;
    }
  }
  img {
    max-width: none;
    width: 100%;
  }
}


.advert-slider {
  position: relative;
  .slick-slide {
    margin: 0 0 2px!important;
  }
  .slick-arrow {
    background-color: rgba(#292d2d, 0.15);
    color: #fff;
    z-index: 10;
    width: 40px;
    height: 40px;
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
    &.slick-prev {
      left: 0;
      &:before {
        border-left: 3px solid #fff;
        border-bottom: 3px solid #fff;
        transform: translate(-30%, -50%) rotate(45deg);
      }
    }
    &.slick-next {
      right: 0;
      &:before {
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        transform: translate(-60%, -50%) rotate(45deg);
      }
    }
  }
}

.advert-slider-pagination {
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  .slick-slide {
    cursor: pointer;
  }
}